.modal {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 400px;
    padding: 1.5em;
    color: white;
    border-radius: .5rem;
    z-index: 999999;
}

.correct {
    background-color: #6ad2b0;
}

.incorrect {
    background-color: #f10e0e;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 100%;
    height: 10px;
    border-radius: 10px;
    color: #BEBEBE;
    background-color: white;
    position: relative;
    margin: .6em auto 0;
}

.loader::before {
    content: "";
    position: absolute;
    margin: 2px;
    inset: 0 100% 0 0;
    border-radius: inherit;
    background: currentColor;
    animation: l6 3s infinite;
}

@keyframes l6 {
    100% {inset:0}
}

/* Movile devices */
@media (max-width: 768px) {

    .modal {
        position: absolute;
        bottom: 30px;
        right: 0px;
        width: 100%;
        height: 100px;
        margin: 0 auto;
        align-items: center;
        color: white;
        border-radius: .5rem;
        z-index: 100;
    }

}