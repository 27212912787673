nav {
  display: flex;
  justify-content: start;
  row-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  padding: 1em
}

.link {
  width: 130px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #bebebe;
  border-radius: .3rem;
  padding: .7em 1em;
  margin: 0 .8em 0 .8em;
  transition: all 0.3s ease-in-out;
}

.active {
    width: 130px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #6C7AE0;
    border-radius: .3rem;
    padding: .7em 1em;
    margin: 0 .8em 0 .8em;
    transition: all 0.3s ease-in-out;
}

/* Movile devices */
@media (max-width: 960px) {

  nav {
    font-size: 0.875em;
    height: 60px;
  }

  nav a:not(:nth-of-type(2)) {
    display: none;
  }

  .link {
    width: 100px;
    text-align: center;
    padding: auto 0;
  }
  
  .active {
    width: 100px;
    text-align: center;
    padding: auto 0;
  }

}