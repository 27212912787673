.container {
    width: 100%;
    margin: 0 auto;
}

.container h2 {
    width: 90%;
    margin: 1em auto;
}

.container form, 
.container .container-form {
    width: 90%;
    margin: 1em auto;
    display: grid;
    grid-template-columns: 1fr 8fr;
    row-gap: 1em;
}

.container form label,
.container .container-form label {
    padding: .5em;
}

.container form input,
.container form textarea,
.container form select,
.container .container-form input,
.container .container-form textarea,
.container .container-form select {
    padding: .5em;
    background-color: aliceblue;
    border-radius: .3rem;
}

.container form input:focus,
.container form textarea:focus,
.container form select:focus {
    border: 1px solid #000;
    background-color: antiquewhite;
    border-radius: .3rem;
}

.container .upkeeplist {
    list-style: none;
    padding-left: 1em;
    padding-top: .5em;
}

.btn_container_alert {
    grid-column-start: 1;
    grid-column-end: 3;
    margin: 2em auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_normal {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
}

.btn_container_button {
    margin: 2em auto;
}

.btn_container_button .btn_add {
    width: 120px;
    margin: 0 auto;
    padding: .7em;
    border-radius: .3rem;
    background-color: #6C7AE0;
    color: white;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
}

.btn_add i {
    margin-right: .6em;
}

.btn_container_button .btn_add:hover {
    transform: scale(1.05);
}

.btn_container_alert .btn_alert {
    width: 100%;
    margin: 0 auto;
    color: white;
    background-color: coral;
    padding: 1.3em;
    border-radius: .3rem;
}


/* Modal save correct or incorrect */
.disable {
    display: none;
}
