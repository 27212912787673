header {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    background-color: #1e1e1e;
    color: #6b9a34;
}

.logo {
    margin: auto 1em;
}

.logo img {
    height: 40px;
}

.logout {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 3em;
    color: white;
    text-decoration: none;
}

header i {
    cursor: pointer;
    margin-left: 1em;
}

/* Movile devices */
@media (max-width: 960px) {

    .container {
        width: 100%;
    }

    .container header {
        width: 100%;
    }

    .logo {
        margin: auto;
    }

    .logo img {
        height: 20px;
    }

    .logout {
        padding-right: 1em;
    }

    .logout span {
        display: none;
    }

}