.footer {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    position: fixed;
    font-size: 0.625rem;
    background-color: white;
}
  
.footer a {
    color: #c0c0c0;
    text-decoration: none;
    display: flex;
    align-items: center;
}

/* Movile devices */
@media (min-width: 576px) {

    /* Footer */
    .footer {
        background-color: transparent;
    }
}