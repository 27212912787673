.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* Filters and buttons styles */
.main {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.disable {
    display: none;
}

/* Multiselector styles */
.filters {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4em;
    margin-top: 2em;
    margin-bottom: 2em;
}

/* Button */
.button {
    display: flex;
    justify-content: end;
    align-items: center;
    border-top: 1px solid antiquewhite;
}

/* Table styles */
.listElements {
    width: 100%;
    margin: 1em auto 2em;
}

table {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    border-spacing: 0;
}
  
th, td {
    padding-right: 10px;
    font-weight: normal;
}

th:first-of-type {
    border-top-left-radius: .3em;
}

th:last-of-type {
    border-top-right-radius: .3em;
}
  
.columns {
    width: 130px;
    padding-left: 1em;
}

.columns:nth-child(1) {
    width: 40%;
    text-align: start;
    padding-left: 2em;
}

.columns:nth-child(2) {
    width: 40%;
    text-align: center;
    padding-left: 2em;
}

.columns:nth-child(3),
.columns:nth-child(4) {
    width: 10%;
    text-align: center;
}

.rows.head th,
.rows td {
    padding-top: 1em;
    padding-bottom: 1em;
}

.table.ver3 tbody tr {
    border-bottom: 1px solid #e5e5e5;
}

.table.ver3 td {
    font-size: .875em;
    color: #808080;
}

.table.ver3 th {
    color: #fff;
    background-color: #6c7ae0;
}

.table.ver3 .rows:hover td {
    background-color: #fcebf5;
}

.table.ver3 .rows td:hover {
    background-color: #e03e9c;
    color: #fff;
}

.table.ver3 .rows td:hover a {
    color: #fff;
}

.table.ver3 td:last-child {
    text-align: center;
}

.table td a {
    text-decoration: none;
    color: #808080;
}

.table td i {
    margin: 0 .6em 0 .6em;
    cursor: pointer;
}

.table td:last-child i:hover {
    transform: scale(1.3);
}
