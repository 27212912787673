.container_home {
    width: 100%;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    padding: 4em;
}

.container_line {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 1em;
    gap: 20px;
    
}

/* Movile devices */
@media (max-width: 960px) {

    .container {
        display: none;
    }

}