.modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 500px;
    height: 500px;
    padding: 1em;
    border-radius: .5rem;
    z-index: 999;
    background-color: #a2d2ff;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.modal .form {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-top: 1em;
}

.modal .datelist {
    list-style: none;
    padding-left: 1em;
    padding-top: .5em;
}

.modal .datelist .datelistitem {
    line-height: 2;
}

.btn_container {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
    margin: 2em auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_container .btn_add {
    width: 100%;
    margin: 0 auto;
    padding: .7em;
    border-radius: .3rem;
    background-color: #6C7AE0;
    color: white;
    font-size: 1em;
    cursor: pointer;
}

.btn_container .btn_add:hover {
    transform: scale(1.05);
}

.modal i:hover {
    transform: scale(1.3);
}

.message_visible {
    font-size: 0.7em;
    color: red;
}

.message_novisible {
    display: none;
}