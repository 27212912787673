:root {
  font-size: 0.785;
  box-sizing: border-box;
}

*,
::before,
::after {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none 0;
}

body {
  font-family: "Rubik", sans-serif;
}